var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "letter-container",
    [
      _c("letter-header"),
      _c("div", [
        _c("div", {}, [_vm._v("MEMORANDUM FOR EMPLOYER")]),
        _c("div", { staticClass: "flex mt-4" }, [
          _c("div", { staticClass: "mr-4" }, [_vm._v("FROM:")]),
          _c("div", {}, [
            _c("div", {}, [_vm._v("340 FTG/UFT")]),
            _c("div", {}, [_vm._v("375 J Street West, Ste 1")]),
            _c("div", {}, [_vm._v("JBSA-Randolph TX 78150")]),
          ]),
        ]),
        _c("div", { staticClass: "flex mt-4" }, [
          _vm._v(
            " SUBJECT: Military Duty Status for " +
              _vm._s(_vm.candidateName) +
              ". "
          ),
        ]),
        _c("div", { staticClass: "mt-4" }, [
          _vm._v(
            " 1: This letter serves as official notification while member's pay orders are still processing. Member was selected for the Air Force Undergraduate Flying Training program. Training starts "
          ),
          _c("span", { staticClass: "font-bold" }, [
            _vm._v(_vm._s(_vm._f("formatDate")(_vm.startDate))),
          ]),
          _vm._v(
            " and will remain on orders for approximately two years to complete the following training: "
          ),
          _c("ol", { staticClass: "my-4 ml-8" }, [
            _c("li", [_vm._v("a. Officer Training School")]),
            _c("li", [_vm._v("b. Initial Flight Training")]),
            _c("li", [_vm._v("c. Undergraduate Flight Training")]),
            _c("li", [_vm._v("d. Weapons System Training")]),
            _c("li", [_vm._v("e. Combat and Water Survival")]),
          ]),
        ]),
        _c("div", { staticClass: "mt-4" }, [
          _vm._v(
            " 2: Upon your request, a certified copy of " +
              _vm._s(_vm.candidateName) +
              "'s orders can be provided after the final routing. "
          ),
        ]),
        _c("div", { staticClass: "mt-4" }, [
          _vm._v(
            " 3: For questions, please contact us at 210-652-6107, or via email 340FTG.UFT.Workflow@us.af.mil "
          ),
        ]),
      ]),
      _c("letter-footer"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }